

































































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  AddOrganizationModal,
  OrganizationCard,
  NetworkComponent,
  QuotaUsage,
} from "@/components";
import { Api } from "@/api";
import { Organization, PlanType, QuotaUsageStatistics } from "@/types";
import { namespace as authNs } from "@/store/auth";

@Component({
  components: {
    AddOrganizationModal,
    OrganizationCard,
    NetworkComponent,
    QuotaUsage,
  },
})
export default class Dashboard extends Vue {
  @Getter("plan", { namespace: authNs }) plan!: PlanType;
  private addOrganizationModalVisible = false;
  private organizationsLoaded = false;

  private onAddOrganizationButtonClick(): void {
    this.addOrganizationModalVisible = true;
  }

  private async getQuotaUsage(): Promise<QuotaUsageStatistics> {
    return await Api.getQuotaStat();
  }

  private async getAllOrganizations(): Promise<Organization[]> {
    return await Api.getAllOrganizations();
  }

  private organizationsCreated(organizations: Organization[]): boolean {
    if (organizations) {
      return organizations.length !== 0;
    }
    return false;
  }

  private rerenderOrganizationsKey = 0;

  private rerenderOrganizations(): void {
    this.rerenderOrganizationsKey += 1;
  }
}
